<template>
  <v-dialog
    :value="dialog"
    :width="!isMobile ? 834 : '100%'"
    persistent
    attach=".v-application--wrap"
    style="z-index: 300"
    @keydown.esc="isDialogOpen = false"
  >
    <div
      class="d-flex justify-center login-dialog-wrapper"
      :class="contentClass"
    >
      <v-btn
        v-if="valuation"
        class="close-valuation"
        icon
        @click="onToggleConfirmDialog(true)"
      >
        <v-icon size="16">mdi-close</v-icon>
      </v-btn>
      <template v-if="!isMobile">
        <v-img
          v-if="!dialogImage"
          src="@/assets/img/loginDialog/huge.png"
          alt="Login Form - huge build"
        />
        <img
          class="dialog-image"
          v-else
          :src="dialogImage"
          alt="Login Form - huge build"
        />
      </template>
      <v-card class="cardPopUp" :width="isMobile ? '100%' : '452px'">
        <div class="dialog-type-wrapper">
          <div
            class="dialog-type-wrapper__item dialog-type-wrapper__item--active"
          >
            Sign in
          </div>
          <div class="dialog-type-wrapper__item" @click="openRegisterDialog">
            Sign Up Now
          </div>
        </div>
        <img
          v-if="isMobile && !dialogTitle"
          src="@/assets/img/loginDialog/hugeMobile.png"
          alt="Login Form - huge build"
          class="mobile-image-dialog"
        />
        <v-card-title class="cardPopUp__title flex-column">
          <v-btn class="custom-close-icon" icon @click="isDialogOpen = false">
            <v-icon :size="isMobile ? '12' : '16'" color="white"
              >mdi-close</v-icon
            >
          </v-btn>
          <v-img
            class="mb-1 xl-logo"
            src="@/assets/img/logo-updated.svg"
            alt="Xchange.Loans - the online marketplace for non-performing loans"
            :width="'182'"
          />
          <div class="cardPopUp__title__text" v-if="!dialogTitle">
            Welcome Back
          </div>
          <div class="cardPopUp__title__text" v-else>{{ dialogTitle }}</div>
        </v-card-title>
        <v-card-text
          class="cardPopUp__text"
          :class="!contentClass && 'paddings'"
        >
          <login-form
            :registerDialogProps="registerDialogProps"
            :text="dialogSubtitle"
            :userEmail="email"
            @afterLoginBtnClick="afterLoginBtnClick"
            :forgotPasswordDialogProps="forgotPasswordDialogProps"
          />
        </v-card-text>
      </v-card>
    </div>

    <ConfirmCloseValuationDialog
      :dialog="confirmDialog"
      @close-dialog="onToggleConfirmDialog(false)"
      @confirm-close-dialog="onCloseRegisterDialog"
    />
  </v-dialog>
</template>

<script>
import LoginForm from "@/components/Auth/LoginForm.vue";
import ConfirmCloseValuationDialog from "@/components/GlobalDialogs/ConfirmCloseValuationDialog.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "LoginDialog",
  components: {
    LoginForm,
    ConfirmCloseValuationDialog,
  },
  data() {
    return {
      email: "",
      confirmDialog: false,
      valuation: false,
      dialog: false,
      resolve: null,
      afterLoginFunc: null,
      closeOnlyAfterLogin: false,
      dialogImage: null,
      contentClass: null,
      dialogTitle: null,
      dialogSubtitle: null,
      registerDialogProps: {},
      forgotPasswordDialogProps: {},
    };
  },
  computed: {
    ...mapState("Helpers", ["authModalOptions"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    loggedIn() {
      return this.$store.state.auth.user && this.$store.state.user;
    },
    isDialogOpen: {
      get() {
        return this.dialog;
      },
      set(v) {
        if (v) {
          this.dialog = v;
        } else {
          if (!this.closeOnlyAfterLogin) {
            this.dialog = v;
          } else {
            if (this.loggedIn) {
              this.dialog = v;
            }
          }
        }
      },
    },
  },
  watch: {
    loggedIn(newVal) {
      if (newVal && this.dialog) {
        this.close(true);
      }
    },
  },
  created() {
    this.$root.openLoginDialog = this.open;
    this.$root.closeLoginDialog = this.close;
  },
  methods: {
    ...mapMutations("Helpers", ["setAuthModalOptions"]),
    onToggleConfirmDialog(value) {
      this.confirmDialog = value;
    },
    onCloseRegisterDialog() {
      this.setAuthModalOptions(null);
      this.close(true);
      this.$router.push("/");
    },
    openRegisterDialog() {
      this.$root.closeLoginDialog();
      this.$root.openRegisterDialog(this.registerDialogProps);
    },
    open(optionsParams = {}) {
      const options = this.authModalOptions || optionsParams;

      this.closeOnlyAfterLogin = options.closeOnlyAfterLogin;
      this.$emit("opened");
      this.email = options.email;
      this.valuation = options.valuation;
      this.dialogImage = options.dialogImage;
      this.dialog = true;
      this.contentClass = options.contentClass;
      this.afterLoginFunc = options.afterLogin;
      this.dialogTitle = options.dialogTitle;
      this.dialogSubtitle = options.dialogSubtitle;
      this.registerDialogProps = options.registerDialogProps;
      this.forgotPasswordDialogProps = options.forgotPasswordDialogProps;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    refreshChangeRoleSubscription() {
      this.$store.commit("unsubscribeRoleChange");
      this.$emit("handleRoleChangeSubscription");
    },
    afterLoginBtnClick() {
      this.refreshChangeRoleSubscription();
      if (this.afterLoginFunc) {
        this.afterLoginFunc();
      }
    },
    close(v) {
      // eslint-disable-next-line no-console
      this.email = "";
      this.dialog = false;
      this.resolve(v);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-dialog-wrapper {
  position: relative;
}

::v-deep {
  .v-messages__message {
    text-align: right;
    font-size: 10px;
  }
  .second-input-with-background .v-input {
    margin-bottom: 12px;
    padding-right: 0;
  }
  .v-card__title {
    padding: 32px 40px 0;
    color: #0f2d59;
    justify-content: flex-start;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.32px;
  }
  .cardPopUp {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .cardPopUp__description {
    color: rgba(0, 17, 84, 0.8);
    font-size: 14px;
    line-height: 20px;
    text-align: start;
    margin-bottom: 12px;
    letter-spacing: 0;
    margin-top: -1px;
  }
  .cardPopUp__text {
    &.paddings {
      padding: 0 40px 0 !important;
    }
  }
  .v-btn {
    margin-bottom: 8px !important;
  }
  .v-input__slot {
    padding-top: 4px;
  }
  .zentinel-logo {
    height: 42px;
    margin-left: 2px;
  }
  .v-size--default {
    min-height: 32px !important;
  }
  .v-size--default {
    font-size: 12px !important;
    height: 32px !important;
  }
  .v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #9aabc2 !important;
    color: #ffffff !important;
    margin-bottom: 6px !important;
  }
  .v-btn--has-bg {
    background-color: #2969ff !important;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 6px !important;
  }
  .v-btn.v-size--small .v-btn__content {
    font-size: 9px;
  }
}
.custom-close-icon {
  position: absolute;
  top: 4px;
  right: 2px;
}
.cardPopUp__title__text {
  align-self: flex-start;
  margin-top: 13px;
  margin-left: -8px;
  margin-bottom: -1px;
  padding-left: 6px;
}
@include mediaQ(
  map-get($mediabreakpoints, "xs"),
  map-get($mediabreakpoints, "sm")
) {
  .login-dialog-wrapper {
    height: 100%;
    min-height: 100vh;
  }
  .mobile-image-dialog {
    width: 100%;
    height: 172px;
  }
  .custom-close-icon {
    background: none;
    min-height: unset !important;
    width: 24px !important;
    height: 24px !important;
    top: 9px;
    right: 9px;
    font-size: 22px;
    z-index: 30;
  }
  .cardPopUp__title {
    padding: 24px 16px 0;
  }
  .cardPopUp__title__text {
    align-self: center;
    margin-top: 8px;
  }
  .cardPopUp__text {
    &.paddings {
      padding: 0 16px 0 !important;
    }
  }
  ::v-deep {
    .cardPopUp__description {
      display: flex !important;
      justify-content: center !important;
      text-align: center !important;
    }
  }
  ::v-deep {
    .login-btn {
      height: 40px !important;
    }
    .v-dialog {
      background: white;
      max-height: none;
      margin: 0;
    }
    .cardPopUp {
      box-shadow: unset !important;
    }
  }
  .custom-close-icon {
    i {
      font-size: 18px !important;
    }
  }
}

.close-valuation {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 300;
  width: 36px;
  height: 36px;
  ::v-deep {
    .v-btn__content i {
      margin: 0 !important;
    }
  }
}
.dialog-type-wrapper {
  width: 100%;
  background: #001154;
  height: 42px;
  padding-left: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  column-gap: 40px;
  &__item {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
    position: relative;
    &--active {
      &::before {
        content: "";
        height: 2px;
        display: block;
        background: #dd226f;
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
  }
}
::v-deep {
  .c-label {
    font-size: 14px !important;
  }
  @media (max-width: 600px) {
    .c-label {
      font-size: 12px !important;
    }
    .v-input__slot {
      font-size: 14px;
    }
    input {
      font-size: 14px;
    }
  }
}

@include mediaQ(600, 960) {
  ::v-deep .v-dialog {
    margin: 0;
    height: 100%;
    max-height: none;

    .login-dialog-wrapper {
      height: 100%;
    }
  }
  .mobile-image-dialog {
    width: 100%;
    max-height: 170px;
    object-fit: cover;
  }
}
</style>
